import { trigger, transition, animate, style, query, animateChild } from '@angular/animations'
export const slideIn = trigger('slideIn', [
  transition(':leave', [
    style({ transform: 'translateX(0)' }),
    animate('0.4s cubic-bezier(.64,-0.01,.3,.98)', style({ transform: 'translateX(100%)' }))
  ]),
  transition(':enter', [
    style({ transform: 'translateX(100%)' }),
    animate('0.4s cubic-bezier(.64,-0.01,.3,.98)', style({ transform: 'translateX(0)' }))
  ])
]);

export const slideInParent = trigger('slideInParent', [
  transition('* => void', [
    query('@*', [animateChild()], { optional: true })
  ])
]);